@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }

  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, "primary");
  $accent: map.get($config, "accent");
  $warn: map.get($config, "warn");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $is-dark: map.get($config, "is-dark");

  .success-snack-bar {
    color: mat.get-color-from-palette($primary, default-contrast);
    background: mat.get-color-from-palette($primary);

    .mat-simple-snackbar-action {
      color: mat.get-color-from-palette($primary, default-contrast);
    }
  }

  .error-snack-bar {
    color: mat.get-color-from-palette($warn, default-contrast);
    background: mat.get-color-from-palette($warn);

    .mat-simple-snackbar-action {
      color: mat.get-color-from-palette($warn, default-contrast);
    }
  }
}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);
}
