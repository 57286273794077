@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  wzc-sites {
    .content-container {
      background: mat.get-color-from-palette($background, card);
    }

    .mat-table-sticky-border-elem-left {
      border-right-color: mat.get-color-from-palette($foreground, divider);
    }
    .mat-table-sticky-border-elem-right {
      border-left-color: mat.get-color-from-palette($foreground, divider);
    }

    .mat-row:hover .mat-cell:not(.mat-table-sticky) {
      background: mat.get-color-from-palette($background, hover);
    }
  }

  wzc-site {
    .mat-tab-group,
    .mat-tab-body-content,
    .mat-list-item.cdk-drag-preview {
      background: mat.get-color-from-palette($background, card);
    }

    .mat-tab-label-content small {
      color: mat.get-color-from-palette($primary, text);
    }
  }

  wzc-site-details {
    .mat-card {
      .mat-card-subtitle {
        color: mat.get-color-from-palette($primary, text);
      }

      .mat-list-base .mat-list-item h3.mat-line {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
    }
  }

  wzc-site-editor,
  wzc-site-social-media-link-editor {
    p.mat-caption {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }

  .mat-option .language-code {
    color: mat.get-color-from-palette($primary, text);
  }

  wzc-linked-sites {
    .mat-nav-list .mat-list-item {
      .url {
        color: mat.get-color-from-palette($accent, text);
      }
    }
  }

  wzc-site-social-media-links {
    .mat-list .mat-list-item {
      .mat-line:nth-child(n + 2) {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
    }
  }

  wzc-site-analytics-config-info,
  wzc-site-customer-care-info {
    .mat-list .mat-list-item {
      .mat-line:nth-child(1) {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
    }
  }

  wzc-site-analytics-editor {
    h2 {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }

  wzc-site-analytics-config-info,
  wzc-site-customer-care-info,
  wzc-site-footer-links,
  wzc-site-social-media-links {
    .mat-toolbar:last-child {
      background: none;
      color: mat.get-color-from-palette($foreground, text);
      border-color: mat.get-color-from-palette($foreground, divider);
    }
  }
}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);

  wzc-sites {
    .mat-toolbar {
      font-family: mat.font-family($config);

      h1 {
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }

    .mat-header-cell {
      font-weight: mat.font-weight($config, body-2);
    }

    .mat-cell.mat-column-flag {
      font-size: 2em;
    }
    .mat-cell.mat-column-title {
      @include mat.typography-level($config, body-2);
    }
  }

  wzc-site-details {
    .mat-list .mat-list-item {
      h3.mat-line {
        @include mat.typography-level($config, caption);
      }
      .mat-line:nth-child(n + 2) {
        font-size: mat.font-size($config, subheading-2);
      }
    }
  }

  wzc-site-analytics-config-info,
  wzc-site-customer-care-info {
    .mat-list .mat-list-item .mat-line:nth-child(1) {
      @include mat.typography-level($config, "caption");
    }
  }
}
