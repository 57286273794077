@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $is-dark: map.get($config, is-dark);

  wzc-main-menu {
    .mat-list-base .mat-list-item {
      &:not(.mat-list-item-disabled) {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }

      &.active {
        color: mat.get-color-from-palette($foreground, text);
        .mat-icon {
          color: mat.get-color-from-palette($primary, text);
        }
        &::before {
          background: mat.get-color-from-palette($primary, text);
        }
      }
    }
  }

  wzc-dashboard {
    .mat-card .mat-icon {
      color: mat.get-color-from-palette($primary, text);
    }
  }
}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);

  wzc-home .site-name {
    @include mat.typography-level($config, headline);
  }

  wzc-user-menu {
    .mat-menu-trigger {
      font-weight: 300;
      letter-spacing: normal;
      text-transform: none;
    }
  }

  wzc-dashboard {
    .mat-card .mat-card-title {
      font-size: mat.font-size($config, body-2);
    }
  }
}
