@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, primary);
  $background: map.get($config, background);

  wzc-sign-in {
    section {
      background: mat.get-color-from-palette($background, card);
      border-bottom-color: mat.get-color-from-palette($primary);
    }

    .subtitle {
      color: mat.get-color-from-palette($primary, text);

      .mat-divider {
        border-top-color: mat.get-color-from-palette($primary, text);
      }
    }

    a {
      color: mat.get-color-from-palette($primary, text);
    }
  }
}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);

  wzc-sign-in {
    footer {
      @include mat.typography-level($config, body-1);
      text-align: center;
      font-style: italic;
    }

    .subtitle {
      text-align: end;

      h2 {
        font-family: mat.font-family($config);
        font-size: mat.font-size($config, headline);
        font-weight: 400;
        font-style: italic;
      }
    }
  }
}
