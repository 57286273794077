@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $background: map.get($config, background);

  .mat-icon[svgicon="logo"] svg {
    .pink {
      fill: mat.get-color-from-palette($primary);
    }
    .dark-pink {
      fill: mat.get-color-from-palette($primary, darker);
    }
    #path0 {
      fill: mat.get-color-from-palette($background, selected-button);
    }
  }

  .mat-toolbar.mat-primary > .mat-icon[svgicon="logo"] svg {
    .pink {
      fill: mat.get-color-from-palette($primary, default-contrast);
    }
    .dark-pink {
      fill: mat.get-color-from-palette($primary, default-contrast, 0.82);
    }
    #path0 {
      fill: mat.get-color-from-palette($primary, default-contrast, 0.42);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}
